import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import HeroGeneric from "../components/HeroGeneric";
import GuideTiles from "../components/GuideTiles";
import CardsIntro from "../components/CardsIntro";
import GuidesSection from "../components/GuidesSection";
import HeroGuidesIndex from "../components/HeroGuidesIndex";
import SimpleBanner from "../components/SimpleBanner";
import ResourcesSection from "../components/ResourcesSection";
import FooterCTA from "../components/FooterCTA";
import FeaturedGuidesBlock from "../components/FeaturedGuidesBlock";

export default function GuidesIndex({ data, pageContext }) {
	const {
		entry,
		firstSectionTiles,
		secondSectionTiles,
		thirdSectionTiles,
		fourthSectionTiles,
	} = data?.craft;
	const {
		heroTitle,
		heroText,
		featuredGuides,
		firstSectionTitle,
		firstSectionText,
		firstSectionCategory,
		firstSectionFeaturedGuide,
		secondSectionTitle,
		secondSectionText,
		secondSectionCategory,
		secondSectionFeaturedGuide,
		thirdSectionTitle,
		thirdSectionCategory,
		fourthSectionTitle,
		fourthSectionCategory,
		simpleBannerTitle,
		simpleBannerText,
		simpleBannerButton,
		simpleBannerImage,
		simpleBannerImageMobile,
		footerBannerTitle,
		footerBannerText,
		footerBannerButton,
		metaTitle,
		metaDescription,
		metaShareImage,
	} = entry ?? [];

	const heroNav = pageContext?.heroNav?.data?.craft;
	const terms = pageContext?.glossaryTerms?.data?.craft?.entries ?? [];
	const { currentUrl } = pageContext;

	return (
		<Layout className="layout--guidesIndex">
			<SEO
				title={metaTitle}
				description={metaDescription}
				image={metaShareImage}
			/>

			<HeroGuidesIndex
				title={heroTitle}
				text={heroText}
				colorTheme="dark"
				nextTheme="light"
				navLinks={heroNav}
				currentUrl={currentUrl}
			/>

			<FeaturedGuidesBlock colorTheme="dark" blocks={featuredGuides} />

			<GuidesSection
				entries={firstSectionTiles}
				title={firstSectionTitle}
				text={firstSectionText}
				category={firstSectionCategory}
				featuredGuide={firstSectionFeaturedGuide}
				terms={terms}
				topBorder={false}
				colorTheme="greenLight"
			/>

			<GuidesSection
				entries={secondSectionTiles}
				title={secondSectionTitle}
				text={secondSectionText}
				category={secondSectionCategory}
				featuredGuide={secondSectionFeaturedGuide}
				terms={terms}
				colorTheme="light"
				prefixCta
			/>

			<GuidesSection
				entries={thirdSectionTiles}
				title={thirdSectionTitle}
				category={thirdSectionCategory}
				colorTheme="light"
				prefixCta
			/>

			<ResourcesSection
				entries={fourthSectionTiles}
				title={fourthSectionTitle}
				category={fourthSectionCategory}
				colorTheme="light"
				prefixCta
			/>

			<SimpleBanner
				title={simpleBannerTitle}
				text={simpleBannerText}
				cta={simpleBannerButton}
				image={simpleBannerImage}
				mobileImage={simpleBannerImageMobile}
			/>

			<FooterCTA
				colorTheme="dark"
				title={footerBannerTitle}
				text={footerBannerText}
				cta={footerBannerButton}
			/>

			{/* Old tiles */}
			{/* <GuideTiles items={tiles} /> */}
		</Layout>
	);
}

export const pageQuery = graphql`
	fragment GuideExcerpt on CraftCMS_guidesInsights_guides_Entry {
		uri
		url
		slug
		title
		heroImage {
			title
			filename
			width
			height
			url
			imagerX(transform: "maxWidth-1920") {
				width
				height
				url
			}
			imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
				width
				height
				url
			}
		}
		guideCategory {
			id
			title
			... on CraftCMS_guidesAndInsightsCategories_Category {
				id
				categoryTheme
				categorySecondaryTitle
				uri
			}
		}
	}

	fragment FeaturedGuideExcerpt on CraftCMS_guidesInsights_guides_Entry {
		...GuideExcerpt
		guideIndexTitle
		guideIndexText
		guideIndexReadingTime
		guideIndexImage {
			title
			filename
			width
			height
			url
			imagerX(transform: "maxWidth-1920") {
				width
				height
				url
			}
			imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
				width
				height
				url
			}
		}
		heroText
		heroImageMobile {
			title
			filename
			width
			height
			url
			imagerX(transform: "maxWidth-1920") {
				width
				height
				url
			}
			imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
				width
				height
				url
			}
		}
	}

	query GuidesIndex(
		$firstSectionCategory: [String]
		$secondSectionCategory: [String]
		$thirdSectionCategory: [String]
		$fourthSectionCategory: [String]
	) {
		craft {
			entry(slug: "guides-insights-index") {
				uri
				url
				slug
				... on CraftCMS_guidesInsightsIndex_guidesInsightsIndex_Entry {
					heroTitle
					heroText
					featuredGuides {
						... on CraftCMS_guidesInsights_guides_Entry {
							...GuideExcerpt
						}
					}
					firstSectionTitle
					firstSectionText
					firstSectionCategory {
						title
						slug
						... on CraftCMS_guidesAndInsightsCategories_Category {
							uri
							categoryTheme
						}
					}
					firstSectionFeaturedGuide {
						...FeaturedGuideExcerpt
					}
					secondSectionTitle
					secondSectionText
					secondSectionCategory {
						title
						slug
						... on CraftCMS_guidesAndInsightsCategories_Category {
							uri
							categoryTheme
						}
					}
					secondSectionFeaturedGuide {
						...FeaturedGuideExcerpt
					}
					thirdSectionTitle
					thirdSectionCategory {
						title
						slug
						... on CraftCMS_guidesAndInsightsCategories_Category {
							uri
							categoryTheme
						}
					}
					fourthSectionTitle
					fourthSectionCategory {
						title
						slug
						... on CraftCMS_guidesAndInsightsCategories_Category {
							uri
							categoryTheme
						}
					}
					simpleBannerTitle
					simpleBannerText
					simpleBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					simpleBannerImage {
						title
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					simpleBannerImageMobile {
						title
						width
						height
						url
						imagerX(transform: "maxWidth-1920") {
							width
							height
							url
						}
						imagerXWp: imagerX(transform: "maxWidth-1920-wp") {
							width
							height
							url
						}
					}
					footerBannerTitle
					footerBannerText
					footerBannerButton {
						element {
							slug
							uri
						}
						ariaLabel
						customText
						target
						text
						title
						type
						url
					}
					metaTitle
					metaDescription
					metaShareImage {
						filename
						url
					}
				}
			}
			terms: entries(type: "terms", section: "glossary") {
				id
				title
				typeHandle
				... on CraftCMS_glossary_terms_Entry {
					definition
				}
			}
			firstSectionTiles: entries(
				section: "guidesInsights"
				relatedToCategories: [{ slug: $firstSectionCategory }]
			) {
				...GuideExcerpt
			}
			secondSectionTiles: entries(
				section: "guidesInsights"
				relatedToCategories: [{ slug: $secondSectionCategory }]
			) {
				...GuideExcerpt
			}
			thirdSectionTiles: entries(
				section: "guidesInsights"
				relatedToCategories: [{ slug: $thirdSectionCategory }]
			) {
				...GuideExcerpt
			}
			fourthSectionTiles: entries(
				section: "guidesInsights"
				relatedToCategories: [{ slug: $fourthSectionCategory }]
			) {
				...GuideExcerpt
			}
		}
	}
`;
